(function($) {

    console.debug('slick config script loaded');

    $('.slick-insta-posts').slick({
        slidesToShow: 5,
        slidesToScroll: 5,
        accessibility: true,
        arrows: true,
        infinite: true,
        responsive: [
            {
                breakpoint: 999,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 739,
                settings: "unslick"
            }
        ]
    }).on('afterChange', function(event, slick, direction){
        doLazyReval();
    });

})(jQuery);